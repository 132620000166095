@media (min-width: 0) and (max-width: 599px) {
  .hidden-xs {
    display: none; } }

@media (min-width: 600px) and (max-width: 767px) {
  .hidden-sm {
    display: none; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-md {
    display: none; } }

@media (min-width: 1024px) and (max-width: 1199px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 1200px) {
  .hidden-xl {
    display: none; } }

* {
  box-sizing: border-box; }

[class^='flix-'] {
  margin: 0;
  padding: 0; }
  [class^='flix-']::before, [class^='flix-']::after {
    box-sizing: border-box; }

@font-face {
  font-family: 'flixicons';
  font-style: normal;
  font-weight: normal;
  src: url("../font/flix.woff2?1529063296902") format("woff2"), url("../font/flix.woff?1529063296902") format("woff"), url("../font/flix.ttf?1529063296902") format("truetype");
  font-display: block; }

.flix-icon-account-attention:before {
  content: '\e801'; }

.flix-icon-account-settings-solid:before {
  content: '\e802'; }

.flix-icon-account-settings:before {
  content: '\e803'; }

.flix-icon-account-solid:before {
  content: '\e804'; }

.flix-icon-account:before {
  content: '\e805'; }

.flix-icon-airport-solid:before {
  content: '\e806'; }

.flix-icon-airport:before {
  content: '\e807'; }

.flix-icon-arrow-down:before {
  content: '\e808'; }

.flix-icon-arrow-left:before {
  content: '\e809'; }

.flix-icon-arrow-right:before {
  content: '\e80a'; }

.flix-icon-arrow-up:before {
  content: '\e80b'; }

.flix-icon-attention-solid:before {
  content: '\e80c'; }

.flix-icon-attention:before {
  content: '\e80d'; }

.flix-icon-beach-solid:before {
  content: '\e80e'; }

.flix-icon-beach:before {
  content: '\e80f'; }

.flix-icon-beer:before {
  content: '\e810'; }

.flix-icon-bike-solid:before {
  content: '\e811'; }

.flix-icon-bike:before {
  content: '\e812'; }

.flix-icon-birthday-solid:before {
  content: '\e813'; }

.flix-icon-birthday:before {
  content: '\e814'; }

.flix-icon-booster-solid:before {
  content: '\e815'; }

.flix-icon-booster:before {
  content: '\e816'; }

.flix-icon-burger:before {
  content: '\e817'; }

.flix-icon-bus-night-solid:before {
  content: '\e818'; }

.flix-icon-bus-night:before {
  content: '\e819'; }

.flix-icon-bus-service-solid:before {
  content: '\e81a'; }

.flix-icon-bus-service:before {
  content: '\e81b'; }

.flix-icon-bus-solid:before {
  content: '\e81c'; }

.flix-icon-bus-stop-solid:before {
  content: '\e81d'; }

.flix-icon-bus-stop:before {
  content: '\e81e'; }

.flix-icon-bus-time-solid:before {
  content: '\e81f'; }

.flix-icon-bus-time:before {
  content: '\e820'; }

.flix-icon-bus:before {
  content: '\e821'; }

.flix-icon-buses:before {
  content: '\e822'; }

.flix-icon-calendar-solid:before {
  content: '\e823'; }

.flix-icon-calendar:before {
  content: '\e824'; }

.flix-icon-cart-solid:before {
  content: '\e825'; }

.flix-icon-cart:before {
  content: '\e826'; }

.flix-icon-chat-solid:before {
  content: '\e827'; }

.flix-icon-chat:before {
  content: '\e828'; }

.flix-icon-check-solid:before {
  content: '\e829'; }

.flix-icon-check:before {
  content: '\e82a'; }

.flix-icon-checkmark-strong-solid:before {
  content: '\e82b'; }

.flix-icon-checkmark-strong:before {
  content: '\e82c'; }

.flix-icon-checkmark:before {
  content: '\e82d'; }

.flix-icon-city-solid:before {
  content: '\e82e'; }

.flix-icon-city:before {
  content: '\e82f'; }

.flix-icon-close:before {
  content: '\e830'; }

.flix-icon-collapse:before {
  content: '\e831'; }

.flix-icon-connection-transfer:before {
  content: '\e832'; }

.flix-icon-connection:before {
  content: '\e833'; }

.flix-icon-credit-card-solid:before {
  content: '\e834'; }

.flix-icon-credit-card:before {
  content: '\e835'; }

.flix-icon-customer-solid:before {
  content: '\e836'; }

.flix-icon-customer:before {
  content: '\e837'; }

.flix-icon-directions-solid:before {
  content: '\e838'; }

.flix-icon-directions:before {
  content: '\e839'; }

.flix-icon-discount-solid:before {
  content: '\e83a'; }

.flix-icon-discount:before {
  content: '\e83b'; }

.flix-icon-drinks-solid:before {
  content: '\e83c'; }

.flix-icon-drinks:before {
  content: '\e83d'; }

.flix-icon-driver-solid:before {
  content: '\e83e'; }

.flix-icon-driver:before {
  content: '\e83f'; }

.flix-icon-entertainment-solid:before {
  content: '\e840'; }

.flix-icon-entertainment:before {
  content: '\e841'; }

.flix-icon-expand:before {
  content: '\e842'; }

.flix-icon-filter:before {
  content: '\e843'; }

.flix-icon-group-solid:before {
  content: '\e844'; }

.flix-icon-group:before {
  content: '\e845'; }

.flix-icon-home-solid:before {
  content: '\e846'; }

.flix-icon-home:before {
  content: '\e847'; }

.flix-icon-info-solid:before {
  content: '\e848'; }

.flix-icon-info:before {
  content: '\e849'; }

.flix-icon-invoice:before {
  content: '\e84a'; }

.flix-icon-label-attention-solid:before {
  content: '\e84b'; }

.flix-icon-label-attention:before {
  content: '\e84c'; }

.flix-icon-label-solid:before {
  content: '\e84d'; }

.flix-icon-label:before {
  content: '\e84e'; }

.flix-icon-leaf-solid:before {
  content: '\e84f'; }

.flix-icon-leaf:before {
  content: '\e850'; }

.flix-icon-location-solid:before {
  content: '\e855'; }

.flix-icon-location:before {
  content: '\e856'; }

.flix-icon-lost-solid:before {
  content: '\e857'; }

.flix-icon-lost:before {
  content: '\e858'; }

.flix-icon-luggage-additional-solid:before {
  content: '\e859'; }

.flix-icon-luggage-additional:before {
  content: '\e85a'; }

.flix-icon-luggage-bulky-solid:before {
  content: '\e85b'; }

.flix-icon-luggage-bulky:before {
  content: '\e85c'; }

.flix-icon-luggage-hand-solid:before {
  content: '\e85d'; }

.flix-icon-luggage-hand:before {
  content: '\e85e'; }

.flix-icon-luggage-solid:before {
  content: '\e85f'; }

.flix-icon-luggage:before {
  content: '\e860'; }

.flix-icon-magnifier-solid:before {
  content: '\e863'; }

.flix-icon-magnifier:before {
  content: '\e864'; }

.flix-icon-map-solid:before {
  content: '\e865'; }

.flix-icon-map:before {
  content: '\e866'; }

.flix-icon-men-women-solid:before {
  content: '\e867'; }

.flix-icon-men-women:before {
  content: '\e868'; }

.flix-icon-minus:before {
  content: '\e869'; }

.flix-icon-mountains-solid:before {
  content: '\e86a'; }

.flix-icon-mountains:before {
  content: '\e86b'; }

.flix-icon-newsletter-solid:before {
  content: '\e86c'; }

.flix-icon-newsletter:before {
  content: '\e86d'; }

.flix-icon-night-solid:before {
  content: '\e86e'; }

.flix-icon-night:before {
  content: '\e86f'; }

.flix-icon-person-solid:before {
  content: '\e870'; }

.flix-icon-person:before {
  content: '\e871'; }

.flix-icon-phone-solid:before {
  content: '\e872'; }

.flix-icon-phone-tablet-solid:before {
  content: '\e873'; }

.flix-icon-phone-tablet:before {
  content: '\e874'; }

.flix-icon-phone:before {
  content: '\e875'; }

.flix-icon-pin-solid:before {
  content: '\e876'; }

.flix-icon-pin:before {
  content: '\e877'; }

.flix-icon-plus:before {
  content: '\e878'; }

.flix-icon-q-and-a-solid:before {
  content: '\e879'; }

.flix-icon-q-and-a:before {
  content: '\e87a'; }

.flix-icon-question-solid:before {
  content: '\e87b'; }

.flix-icon-question:before {
  content: '\e87c'; }

.flix-icon-rebook-solid:before {
  content: '\e87f'; }

.flix-icon-rebook:before {
  content: '\e880'; }

.flix-icon-refresh:before {
  content: '\e881'; }

.flix-icon-seat-belt-solid:before {
  content: '\e882'; }

.flix-icon-seat-belt:before {
  content: '\e883'; }

.flix-icon-seat-solid:before {
  content: '\e884'; }

.flix-icon-seat:before {
  content: '\e885'; }

.flix-icon-secure-payment-solid:before {
  content: '\e886'; }

.flix-icon-secure-payment:before {
  content: '\e887'; }

.flix-icon-security-solid:before {
  content: '\e888'; }

.flix-icon-security:before {
  content: '\e889'; }

.flix-icon-settings-solid:before {
  content: '\e88a'; }

.flix-icon-settings:before {
  content: '\e88b'; }

.flix-icon-sit-solid:before {
  content: '\e88c'; }

.flix-icon-sit:before {
  content: '\e88d'; }

.flix-icon-snacks-solid:before {
  content: '\e88e'; }

.flix-icon-snacks:before {
  content: '\e88f'; }

.flix-icon-socket-solid:before {
  content: '\e890'; }

.flix-icon-socket:before {
  content: '\e891'; }

.flix-icon-switch-vertical:before {
  content: '\e892'; }

.flix-icon-switch:before {
  content: '\e893'; }

.flix-icon-ticket-solid:before {
  content: '\e894'; }

.flix-icon-ticket-special-solid:before {
  content: '\e895'; }

.flix-icon-ticket-special:before {
  content: '\e896'; }

.flix-icon-ticket:before {
  content: '\e897'; }

.flix-icon-time-solid:before {
  content: '\e89a'; }

.flix-icon-time:before {
  content: '\e89b'; }

.flix-icon-toilet-solid:before {
  content: '\e89c'; }

.flix-icon-toilet:before {
  content: '\e89d'; }

.flix-icon-train-night-solid:before {
  content: '\e89e'; }

.flix-icon-train-night:before {
  content: '\e89f'; }

.flix-icon-train-service-solid:before {
  content: '\e8a0'; }

.flix-icon-train-service:before {
  content: '\e8a1'; }

.flix-icon-train-solid:before {
  content: '\e8a2'; }

.flix-icon-train-stop-solid:before {
  content: '\e8a3'; }

.flix-icon-train-stop:before {
  content: '\e8a4'; }

.flix-icon-train-time-solid:before {
  content: '\e8a5'; }

.flix-icon-train-time:before {
  content: '\e8a6'; }

.flix-icon-train:before {
  content: '\e8a7'; }

.flix-icon-transfer-solid:before {
  content: '\e8a8'; }

.flix-icon-transfer:before {
  content: '\e8a9'; }

.flix-icon-trip-solid:before {
  content: '\e8aa'; }

.flix-icon-trip:before {
  content: '\e8ab'; }

.flix-icon-voucher-solid:before {
  content: '\e8ac'; }

.flix-icon-voucher:before {
  content: '\e8ad'; }

.flix-icon-warning:before {
  content: '\e8ae'; }

.flix-icon-wheelchair-solid:before {
  content: '\e8af'; }

.flix-icon-wheelchair:before {
  content: '\e8b0'; }

.flix-icon-wifi-solid:before {
  content: '\e8b1'; }

.flix-icon-wifi:before {
  content: '\e8b2'; }

.flix-icon-account-attention-solid:before {
  content: '\e800'; }

.flix-icon-real-time-solid:before {
  content: '\e8b3'; }

.flix-icon-real-time:before {
  content: '\e8b4'; }

.flix-icon-stopwatch-solid:before {
  content: '\e87d'; }

.flix-icon-stopwatch:before {
  content: '\e87e'; }

.flix-icon-link-solid:before {
  content: '\e851'; }

.flix-icon-link:before {
  content: '\e852'; }

.flix-icon-ebus-solid:before {
  content: '\e8b5'; }

.flix-icon-ebus:before {
  content: '\e8b6'; }

[class^='flix-icon-']:before,
[class*=' flix-icon-']:before {
  display: inline-block;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  font-family: 'flixicons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.flix-icon {
  display: inline-block;
  font-size: 1.2em; }
  .flix-icon--sm {
    font-size: 25px; }
  .flix-icon--lg {
    font-size: 50px; }

body {
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em;
  color: #444; }

.flix-text h1, .flix-h1, .flix-text h2, .flix-h2, .flix-text h3, .flix-h3, .flix-text h4, .flix-h4 {
  color: #444;
  font-weight: 700; }

.flix-text,
.flix-text p {
  margin: 0;
  margin-bottom: 15px;
  color: #444;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em; }

.flix-fineprint {
  margin: 0;
  margin-bottom: 15px;
  line-height: 17px;
  font-size: 14px;
  font-weight: 300; }

.flix-text a, .flix-link {
  font-weight: 400;
  color: #00b5e2;
  text-decoration: none;
  background-color: transparent; }
  .flix-text a:hover, .flix-text a:active, .flix-text a:focus, .flix-link:hover, .flix-link:active, .flix-link:focus {
    color: #00b5e2;
    text-decoration: underline; }

.flix-text h1, .flix-h1 {
  margin: 0;
  padding: 0 0 30px;
  font-size: 28px;
  line-height: 34px; }

.flix-text h2, .flix-h2 {
  margin: 0;
  padding: 15px 0;
  font-size: 22px;
  line-height: 28px; }

.flix-text h3, .flix-h3 {
  margin: 0;
  padding: 15px 0;
  font-size: 18px;
  line-height: 24px; }

.flix-text h4, .flix-h4 {
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
  line-height: 22px; }

.flix-list,
.flix-text ul {
  margin: 0 0 15px;
  padding-left: 15px;
  list-style-type: none; }
  .flix-list__item,
  .flix-list li,
  .flix-text ul__item,
  .flix-text ul li {
    position: relative;
    padding-left: 19px;
    line-height: 1.375em; }
    .flix-list__item::before,
    .flix-list li::before,
    .flix-text ul__item::before,
    .flix-text ul li::before {
      content: '';
      position: absolute;
      top: 0.6875em;
      left: 0;
      display: block;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      border-radius: 10px;
      background: #444; }
    .flix-list__item .flix-list,
    .flix-list__item ul,
    .flix-list li .flix-list,
    .flix-list li ul,
    .flix-text ul__item .flix-list,
    .flix-text ul__item ul,
    .flix-text ul li .flix-list,
    .flix-text ul li ul {
      margin: 0; }

.flix-list-general, .flix-link-list {
  margin: 0;
  list-style-type: none;
  padding-left: 0; }
  .flix-list-general__item {
    min-height: 40px;
    padding: 8px 0;
    font-size: 16px;
    color: #444;
    border-bottom: 1px solid #e1e1e1; }
    .flix-list-general__item:last-child {
      border-bottom: 0; }

.flix-link-list__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #e1e1e1; }
  .flix-link-list__item:after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e80a';
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -11px; }
  .flix-link-list__item:hover, .flix-link-list__item:active, .flix-link-list__item:focus {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px; }
  .flix-link-list__item:last-child {
    border-bottom: 0; }

.flix-link-list__link {
  width: 100%;
  padding: 5px 30px 5px 15px;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: #444;
  cursor: pointer; }
  .flix-link-list__link:hover, .flix-link-list__link:active, .flix-link-list__link:focus {
    text-decoration: none; }

.flix-text blockquote, .flix-blockquote {
  position: relative;
  margin: 0 0 15px;
  padding: 30px 0 0 80px; }
  .flix-text blockquote::before, .flix-blockquote::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 40px;
    background: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159.8 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%238c8c8c;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Equote1%3C/title%3E%3Cpath class='cls-1' d='M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z'/%3E%3C/svg%3E"); }

.flix-table__wrap {
  display: inline-block;
  margin-bottom: 15px;
  padding: 21px 0;
  border: 1px solid #e1e1e1;
  border-radius: 5px; }

.flix-table {
  border-collapse: separate;
  border-spacing: 30px 0; }
  .flix-table__header th,
  .flix-table__header td {
    font-weight: 700; }
  .flix-table tr:last-child td {
    border-bottom: 0; }

.flix-text h1--section-header, .flix-h1--section-header, .flix-text h2--section-header, .flix-h2--section-header, .flix-text h3--section-header, .flix-h3--section-header, .flix-text h4--section-header, .flix-h4--section-header {
  padding-top: 0; }

.flix-table__header th,
.flix-table__header td, .flix-table td {
  padding: 9px 0;
  text-align: left;
  border-bottom: 1px solid #e1e1e1; }

.flix-fieldset {
  margin: 0;
  padding: 0;
  border: 0; }
  .flix-fieldset .flix-fieldset__item {
    margin-top: 8px;
    margin-bottom: 0; }

.flix-legend {
  display: block;
  padding-bottom: 2px;
  color: #757575;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.flix-control {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .flix-control {
      margin-bottom: 15px; } }
  .flix-control--is-last {
    margin-bottom: 0; }
  .flix-control--small {
    width: 50%; }

.flix-label {
  position: absolute;
  top: -6px;
  left: 10px;
  display: block;
  padding: 0 5px;
  line-height: 1em;
  color: #444;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  background: #fff;
  z-index: 1; }
  @media (min-width: 1200px) {
    .flix-label {
      position: static;
      top: auto;
      left: auto;
      padding: 0 0 2px;
      line-height: 1.375em;
      color: #757575;
      font-size: 16px;
      background: transparent; } }
  .flix-label--required:after {
    content: '*';
    display: inline; }

.flix-btn {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 145px;
  min-height: 36px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  border: 0;
  border-radius: 5px;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none; }
  @media (min-width: 600px) {
    .flix-btn {
      width: auto; } }
  @media (min-width: 1200px) {
    .flix-btn {
      padding-top: 6px;
      padding-bottom: 6px; } }
  .flix-btn--block {
    display: block;
    width: 100%; }
  .flix-btn--primary {
    border: 0;
    background: #ffad00;
    color: #fff; }
    .flix-btn--primary:hover, .flix-btn--primary:active {
      background: #c68600;
      color: #fff;
      text-decoration: none; }
  .flix-btn--secondary {
    border: 0;
    background: #73d700;
    color: #fff; }
    .flix-btn--secondary:hover, .flix-btn--secondary:active {
      background: #5cac00;
      color: #fff;
      text-decoration: none; }
  .flix-btn--danger {
    border: 0;
    background: #ff5700;
    color: #fff; }
    .flix-btn--danger:hover, .flix-btn--danger:active {
      background: #c64300;
      color: #fff;
      text-decoration: none; }
  .flix-btn--tertiary {
    padding-top: 9px;
    padding-bottom: 9px;
    border: 1px solid #73d700;
    background: #fff;
    color: #73d700; }
    @media (min-width: 1200px) {
      .flix-btn--tertiary {
        padding-top: 5px;
        padding-bottom: 5px; } }
    .flix-btn--tertiary:hover, .flix-btn--tertiary:active {
      background-color: #5cac00;
      color: #fff;
      text-decoration: none; }
  .flix-btn--icon {
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 13px;
    background: #8b8b8b;
    cursor: pointer; }
    .flix-btn--icon:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -5px;
      color: #fff;
      font-family: "flixicons";
      font-size: 10px;
      content: '\e80c'; }
  .flix-btn--disabled {
    border: 0;
    background: #c8c8c8;
    color: #fff; }
    .flix-btn--disabled:hover {
      background: #c8c8c8;
      color: #fff;
      text-decoration: none; }
  .flix-btn--has-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .flix-btn__icon::before {
    margin-left: 0; }

.flix-btn + .flix-btn {
  margin-top: 15px; }
  @media (min-width: 600px) {
    .flix-btn + .flix-btn {
      margin-top: 0;
      margin-left: 15px; } }

.flix-btn-arrow {
  display: inline-block;
  position: relative;
  min-height: 0;
  color: #444;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer; }
  .flix-btn-arrow:hover, .flix-btn-arrow:focus, .flix-btn-arrow:active {
    text-decoration: none; }
  .flix-btn-arrow::after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e80a'; }
  .flix-btn-arrow--back::after {
    content: ''; }
  .flix-btn-arrow--back::before {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e809';
    margin-left: 0; }
  .flix-btn-arrow--top::after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e80b'; }
  .flix-btn-arrow--bottom::after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e808'; }
  .flix-btn-arrow--strong {
    color: #ffad00; }
    .flix-btn-arrow--strong::before, .flix-btn-arrow--strong::after {
      color: #ffad00; }

.flix-input {
  appearance: none;
  position: relative;
  width: 100%;
  padding: 0 15px;
  height: 44px;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em;
  text-overflow: ellipsis;
  color: #444;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c8c8c8; }
  .flix-input.placeholder {
    color: #757575;
    font-style: italic; }
  .flix-input:-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-input::-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-input:-ms-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-input::-webkit-input-placeholder {
    color: #757575;
    font-style: italic; }
  @media (min-width: 1200px) {
    .flix-input {
      height: 36px; } }
  .flix-input:focus, .flix-input--active {
    border: 1px solid #757575;
    outline: none; }
  .flix-input[disabled], .flix-input--disabled {
    opacity: .5; }
  .flix-input--valid {
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3E%3Ctitle%3Eexport board 2%3C/title%3E%3Cpath d='M4.66,7.57a.75.75,0,0,1-.53-.22l-3-3A.75.75,0,0,1,2.23,3.33L4.66,5.76,9.77.65a.75.75,0,0,1,1.06,1.06L5.19,7.35A.75.75,0,0,1,4.66,7.57Z' style='fill:%2373d700'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 21px 14px; }
  .flix-input--error {
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 12'%3E%3Ctitle%3Eexport board 1%3C/title%3E%3Cpath d='M1.5,7.86a.75.75,0,0,1-.75-.75V1.83a.75.75,0,0,1,1.5,0V7.11A.75.75,0,0,1,1.5,7.86Z' style='fill:%23ff7300'/%3E%3Ccircle cx='1.5' cy='9.92' r='1' style='fill:%23ff7300'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 5px 20px;
    border: 1px solid #ff5700; }
  .flix-input--has-icon {
    padding-left: 40px; }

.flix-field {
  position: relative; }
  .flix-field__icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 2; }
    .flix-field__icon::before {
      margin-left: 0; }

.flix-select {
  appearance: none;
  position: relative;
  width: 100%;
  padding: 0 15px;
  height: 44px;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em;
  text-overflow: ellipsis;
  color: #444;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.87 4.92'%3E%3Ctitle%3Eflix-circle-arr%3C/title%3E%3Cpath d='M4.46,4.77a1.74,1.74,0,0,1-1.24-.51L.4,1.43A.75.75,0,0,1,1.46.37L4.28,3.2a.25.25,0,0,0,.35,0L7.47.37A.75.75,0,0,1,8.53,1.43L5.7,4.26A1.74,1.74,0,0,1,4.46,4.77Z' style='fill:%238b8b8b'/%3E%3C/svg%3E");
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  outline: 0;
  padding-right: 40px; }
  .flix-select.placeholder {
    color: #757575;
    font-style: italic; }
  .flix-select:-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-select::-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-select:-ms-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-select::-webkit-input-placeholder {
    color: #757575;
    font-style: italic; }
  @media (min-width: 1200px) {
    .flix-select {
      height: 36px; } }
  .flix-select:hover, .flix-select:active, .flix-select:focus {
    outline: 0; }
  .flix-select:focus, .flix-select--active {
    border: 1px solid #757575;
    outline: none; }
  .flix-select[disabled], .flix-select--disabled {
    opacity: .5; }
  .flix-select--valid {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.87 4.92'%3E%3Ctitle%3Eflix-circle-arr%3C/title%3E%3Cpath d='M4.46,4.77a1.74,1.74,0,0,1-1.24-.51L.4,1.43A.75.75,0,0,1,1.46.37L4.28,3.2a.25.25,0,0,0,.35,0L7.47.37A.75.75,0,0,1,8.53,1.43L5.7,4.26A1.74,1.74,0,0,1,4.46,4.77Z' style='fill:%238b8b8b'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3E%3Ctitle%3Eexport board 2%3C/title%3E%3Cpath d='M4.66,7.57a.75.75,0,0,1-.53-.22l-3-3A.75.75,0,0,1,2.23,3.33L4.66,5.76,9.77.65a.75.75,0,0,1,1.06,1.06L5.19,7.35A.75.75,0,0,1,4.66,7.57Z' style='fill:%2373d700'/%3E%3C/svg%3E");
    background-position: right 15px center, right 40px center;
    background-repeat: no-repeat;
    background-size: 14px 14px, 21px 14px; }
  .flix-select--error {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.87 4.92'%3E%3Ctitle%3Eflix-circle-arr%3C/title%3E%3Cpath d='M4.46,4.77a1.74,1.74,0,0,1-1.24-.51L.4,1.43A.75.75,0,0,1,1.46.37L4.28,3.2a.25.25,0,0,0,.35,0L7.47.37A.75.75,0,0,1,8.53,1.43L5.7,4.26A1.74,1.74,0,0,1,4.46,4.77Z' style='fill:%238b8b8b'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 12'%3E%3Ctitle%3Eexport board 1%3C/title%3E%3Cpath d='M1.5,7.86a.75.75,0,0,1-.75-.75V1.83a.75.75,0,0,1,1.5,0V7.11A.75.75,0,0,1,1.5,7.86Z' style='fill:%23ff7300'/%3E%3Ccircle cx='1.5' cy='9.92' r='1' style='fill:%23ff7300'/%3E%3C/svg%3E");
    background-position: right 15px center, right 40px center;
    background-repeat: no-repeat;
    background-size: 14px 14px, 5px 20px;
    border: 1px solid #ff5700; }
  .flix-select ::-ms-expand {
    display: none; }

.flix-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .flix-quantity__label {
    align-self: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px; }
    .flix-quantity__label small {
      display: block;
      font-size: 14px;
      font-weight: 300; }
  .flix-quantity__picker {
    display: flex;
    align-items: stretch; }
    .flix-quantity__picker--active .flix-quantity__input {
      border: 1px solid #757575; }
    .flix-quantity__picker--disabled .flix-quantity__input,
    .flix-quantity__picker--disabled .flix-quantity__button {
      opacity: .5; }
    .flix-quantity__picker--error .flix-quantity__input {
      border: 1px solid #ff5700; }
  .flix-quantity__input {
    align-self: center;
    appearance: none;
    position: relative;
    width: 100%;
    padding: 0 15px;
    height: 44px;
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.375em;
    text-overflow: ellipsis;
    color: #444;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #c8c8c8;
    width: 60px;
    text-align: center;
    -moz-appearance: textfield; }
    .flix-quantity__input.placeholder {
      color: #757575;
      font-style: italic; }
    .flix-quantity__input:-moz-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-quantity__input::-moz-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-quantity__input:-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-quantity__input::-webkit-input-placeholder {
      color: #757575;
      font-style: italic; }
    @media (min-width: 1200px) {
      .flix-quantity__input {
        height: 36px; } }
    .flix-quantity__input::-webkit-inner-spin-button, .flix-quantity__input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .flix-quantity__input:focus {
      border: 1px solid #757575; }
  .flix-quantity__button {
    width: 35px;
    padding: 0;
    font-size: 16px;
    color: #8b8b8b;
    background: transparent;
    border: 0;
    cursor: pointer; }
    .flix-quantity__button--disabled {
      opacity: .5;
      cursor: not-allowed; }
    .flix-quantity__button:after, .flix-quantity__button:before {
      font-family: "flixicons";
      font-style: normal;
      font-variant: normal;
      font-weight: 300;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      speak: none;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font-size: 1.2em; }
    .flix-quantity__button--minus {
      padding-right: 15px; }
      .flix-quantity__button--minus:after {
        content: '\e869'; }
    .flix-quantity__button--plus {
      padding-left: 15px; }
      .flix-quantity__button--plus:after {
        content: '\e878'; }

.flix-checkbox {
  position: absolute;
  top: 2px;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  padding: 0; }

.flix-checkbox-label {
  display: block;
  padding-left: 30px; }

.flix-radio {
  position: absolute;
  top: 3px;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  padding: 0; }

.flix-radio-label {
  display: block;
  padding-left: 30px; }

.flix-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 5px;
  margin: 0 0 7px; }
  .flix-switch input {
    display: none; }
    .flix-switch input:checked + .flix-switch__slider {
      background-color: #ffad00; }
    .flix-switch input:focus + .flix-switch__slider {
      box-shadow: 0 0 1px #ffad00; }
    .flix-switch input:checked + .flix-switch__slider:before {
      transform: translateX(18px); }

.flix-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c8c8c8;
  transition: .4s;
  border-radius: 2.5px; }
  .flix-switch__slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 0;
    top: 50%;
    margin-top: -11px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    transition: .4s;
    border-radius: 50%; }

.flix-textarea {
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px 15px;
  min-height: 118px;
  vertical-align: top;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em;
  color: #444;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
  overflow: auto; }
  .flix-textarea.placeholder {
    color: #757575;
    font-style: italic; }
  .flix-textarea:-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-textarea::-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-textarea:-ms-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-textarea::-webkit-input-placeholder {
    color: #757575;
    font-style: italic; }
  @media (min-width: 1200px) {
    .flix-textarea {
      padding: 5px 15px;
      min-height: 111px; } }
  .flix-textarea:focus, .flix-textarea--active {
    color: #444;
    border: 1px solid #757575;
    outline: none; }
  .flix-textarea[disabled], .flix-textarea--disabled {
    opacity: .5; }
  .flix-textarea--valid {
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3E%3Ctitle%3Eexport board 2%3C/title%3E%3Cpath d='M4.66,7.57a.75.75,0,0,1-.53-.22l-3-3A.75.75,0,0,1,2.23,3.33L4.66,5.76,9.77.65a.75.75,0,0,1,1.06,1.06L5.19,7.35A.75.75,0,0,1,4.66,7.57Z' style='fill:%2373d700'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 10px;
    background-size: 21px 14px; }
  .flix-textarea--error {
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 12'%3E%3Ctitle%3Eexport board 1%3C/title%3E%3Cpath d='M1.5,7.86a.75.75,0,0,1-.75-.75V1.83a.75.75,0,0,1,1.5,0V7.11A.75.75,0,0,1,1.5,7.86Z' style='fill:%23ff7300'/%3E%3Ccircle cx='1.5' cy='9.92' r='1' style='fill:%23ff7300'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 8px;
    background-size: 5px 20px;
    border: 1px solid #ff5700; }

.flix-tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  width: 250px;
  min-height: 44px;
  margin-left: 15px;
  padding: 15px;
  background: #fff;
  color: #444;
  text-decoration: none;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 10;
  transform: translateY(-50%);
  display: none; }
  .flix-tooltip::before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 15px;
    height: 15px;
    background: #fff;
    box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    content: ''; }
  .flix-tooltip--active {
    display: flex;
    text-align: left; }
  .flix-tooltip--left {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 15px; }
    .flix-tooltip--left::before {
      right: 0;
      left: auto;
      box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1);
      transform: translateY(-50%) translateX(50%) rotate(45deg); }
  .flix-tooltip--top {
    top: auto;
    left: 50%;
    bottom: 100%;
    margin-left: 0;
    margin-bottom: 15px;
    transform: translateY(0%) translateX(-50%); }
    .flix-tooltip--top::before {
      top: auto;
      left: 50%;
      bottom: 0;
      margin-left: 0;
      margin-bottom: 15px;
      transform: translateY(150%) translateX(-50%) rotate(-45deg); }
  .flix-tooltip--bottom {
    top: 100%;
    left: 50%;
    margin-left: 0;
    margin-top: 15px;
    transform: translateY(0%) translateX(-50%); }
    .flix-tooltip--bottom::before {
      top: 0;
      left: 50%;
      box-shadow: -3px -3px 5px rgba(0, 0, 0, 0.1);
      transform: translateY(-50%) translateX(-50%) rotate(45deg); }
  .flix-tooltip__close {
    cursor: pointer; }

.flix-tooltip-target {
  position: relative; }

.flix-element-has-hint {
  position: relative; }
  .flix-element-has-hint::before, .flix-element-has-hint::after {
    display: none; }
  .flix-element-has-hint::before {
    position: absolute;
    left: 100%;
    top: 50%;
    width: 250px;
    min-height: 44px;
    margin-left: 15px;
    padding: 15px;
    background: #fff;
    color: #444;
    text-decoration: none;
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    z-index: 10;
    transform: translateY(-50%);
    content: attr(data-hint);
    width: auto;
    min-width: 150px; }
  .flix-element-has-hint::after {
    position: absolute;
    left: 0;
    top: 50%;
    width: 15px;
    height: 15px;
    background: #fff;
    box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    content: '';
    left: 100%;
    transform: translateY(-50%) translateX(50%) rotate(45deg); }
  .flix-element-has-hint--error::before, .flix-element-has-hint--error::after {
    background: #ff5700;
    color: #fff; }
  .flix-element-has-hint--left::before {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 15px; }
  .flix-element-has-hint--left::after {
    left: auto;
    right: 100%;
    transform: translateY(-50%) translateX(-50%) rotate(-135deg); }
  .flix-element-has-hint--top::before {
    top: auto;
    left: 50%;
    bottom: 100%;
    margin-left: 0;
    margin-bottom: 15px;
    transform: translateY(0%) translateX(-50%); }
  .flix-element-has-hint--top::after {
    top: auto;
    left: 50%;
    bottom: 100%;
    transform: translateY(-50%) translateX(-50%) rotate(-45deg); }
  .flix-element-has-hint--bottom::before {
    top: 100%;
    left: 50%;
    margin-left: 0;
    margin-top: 15px;
    transform: translateY(0%) translateX(-50%); }
  .flix-element-has-hint--bottom::after {
    top: 100%;
    left: 50%;
    transform: translateY(50%) translateX(-50%) rotate(135deg); }

.flix-element-has-hint:hover::before, .flix-element-has-hint:hover::after, .flix-element-has-hint:focus::before, .flix-element-has-hint:focus::after, .flix-element-has-hint--active::before, .flix-element-has-hint--active::after {
  display: block; }

[type='range'] {
  appearance: none;
  margin: 11px 0;
  width: 100%; }
  [type='range']:focus {
    outline: 0; }
    [type='range']:focus::-webkit-slider-runnable-track {
      background: #ffb51a; }
    [type='range']:focus::-ms-fill-lower {
      background: #ffad00; }
    [type='range']:focus::-ms-fill-upper {
      background: #ffb51a; }
  [type='range']::-webkit-slider-runnable-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    background: #ffad00;
    border: 0px solid #ffad00;
    border-radius: 2.5px; }
  [type='range']::-webkit-slider-thumb {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 11px;
    cursor: pointer;
    height: 22px;
    width: 22px;
    -webkit-appearance: none;
    margin-top: -8.5px; }
  [type='range']::-moz-range-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    background: #ffad00;
    border: 0px solid #ffad00;
    border-radius: 2.5px; }
  [type='range']::-moz-range-thumb {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 11px;
    cursor: pointer;
    height: 22px;
    width: 22px; }
  [type='range']::-ms-track {
    cursor: pointer;
    height: 5px;
    transition: all .2s ease;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: 11px 0;
    color: transparent; }
  [type='range']::-ms-fill-lower {
    background: #e69c00;
    border: 0px solid #ffad00;
    border-radius: 5px; }
  [type='range']::-ms-fill-upper {
    background: #ffad00;
    border: 0px solid #ffad00;
    border-radius: 5px; }
  [type='range']::-ms-thumb {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 11px;
    cursor: pointer;
    height: 22px;
    width: 22px;
    margin-top: 0; }

.flix-range-slider {
  position: relative;
  height: 22px;
  text-align: center; }

.flix-range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  height: 22px;
  outline: none;
  margin: 0;
  padding: 0; }

.flix-range-slider input::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0; }

.flix-range-slider input::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  appearance: none; }

.flix-range-slider input::-moz-range-track {
  position: relative;
  z-index: -1;
  border: 0; }

.flix-range-slider input:last-of-type::-moz-range-track {
  appearance: none;
  background: none transparent;
  border: 0; }

.flix-range-slider [type=range]::-moz-focus-outer {
  border: 0; }

.flix-nav-horizontal {
  margin: 0;
  padding: 0;
  margin-bottom: 30px; }
  .flix-nav-horizontal--centered {
    text-align: center; }
  .flix-nav-horizontal--right {
    text-align: right; }
  .flix-nav-horizontal--left {
    text-align: left; }
  .flix-nav-horizontal__items {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end; }
  .flix-nav-horizontal__item {
    position: relative;
    max-width: 50%;
    list-style-type: none;
    text-align: center; }
  .flix-nav-horizontal__item + .flix-nav-horizontal__item::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 13px;
    display: inline-block;
    height: 16px;
    border-left: 1px solid #e1e1e1; }
  .flix-nav-horizontal__link {
    display: inline-block;
    width: 100%;
    padding: 9px 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.375em;
    color: #444;
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-horizontal__link:hover, .flix-nav-horizontal__link:active, .flix-nav-horizontal__link:focus, .flix-nav-horizontal__link--active {
      text-decoration: none;
      color: #ffad00; }
      .flix-nav-horizontal__link:hover::before, .flix-nav-horizontal__link:active::before, .flix-nav-horizontal__link:focus::before, .flix-nav-horizontal__link--active::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        height: 0;
        border-bottom: 4px solid #ffad00; }

.flix-nav-side, .flix-nav-side-subnav {
  margin: 0;
  padding: 0;
  position: relative; }
  .flix-nav-side--centered {
    text-align: center; }
  .flix-nav-side__item, .flix-nav-side-subnav__item {
    position: relative;
    display: block; }
  .flix-nav-side__link, .flix-nav-side-subnav__link {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.375em;
    color: #444;
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-side__link:hover, .flix-nav-side-subnav__link:hover, .flix-nav-side__link:active, .flix-nav-side-subnav__link:active, .flix-nav-side__link:focus, .flix-nav-side-subnav__link:focus {
      color: #ffad00;
      text-decoration: none; }
  .flix-nav-side__link--active {
    color: #ffad00; }
  .flix-nav-side__link--has-subnav:after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e808'; }
  .flix-nav-side__link--has-subnav-open:after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e80b'; }
  .flix-nav-side-subnav {
    padding-left: 15px; }
    .flix-nav-side-subnav__link--active {
      color: #ffad00; }

.flix-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #73d700;
  -webkit-font-smoothing: subpixel-antialiased;
  z-index: 1000; }
  .flix-header--unfixed {
    position: static; }
  .flix-header__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-width: 320px;
    max-width: 1200px;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .flix-header__inner {
        padding: 0 15px; } }

.flix-header-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  background: none;
  color: #fff;
  border: 0;
  font-size: 16px; }

.flix-header-brand {
  flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
  margin-right: 0;
  order: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 60px; }
  @media (min-width: 600px) {
    .flix-header-brand {
      flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
      margin-right: 15px;
      margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); } }
  @media (min-width: 1200px) {
    .flix-header-brand {
      flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
      margin-right: 15px;
      justify-content: flex-start;
      margin-left: 0; } }
  .flix-header-brand__link {
    display: flex;
    align-items: center; }
  .flix-header-brand__img {
    height: 22px;
    width: auto; }

.flix-header-widgets {
  display: none; }
  @media (min-width: 600px) {
    .flix-header-widgets {
      display: flex;
      flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
      margin-right: 0;
      order: 2;
      justify-content: flex-end;
      padding-right: 15px; } }
  @media (min-width: 1200px) {
    .flix-header-widgets {
      flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
      margin-right: 0;
      order: 3;
      padding-right: 0; } }

.flix-header-nav-wrapper {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: -250px;
  width: 250px;
  overflow-y: auto;
  padding: 0 15px;
  background: #fff;
  box-shadow: none;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
  transition: left 250ms ease, box-shadow 250ms ease; }
  @media (min-width: 600px) {
    .flix-header-nav-wrapper {
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      width: auto;
      overflow-y: visible;
      padding: 0;
      background: #5cac00;
      box-shadow: none;
      z-index: 1; } }
  @media (min-width: 1200px) {
    .flix-header-nav-wrapper {
      background: transparent; } }
  .flix-header-nav-wrapper--visible {
    left: 0;
    box-shadow: 0 9999px 0 9999px rgba(0, 0, 0, 0.15); }
    @media (min-width: 600px) {
      .flix-header-nav-wrapper--visible {
        box-shadow: none; } }

.flix-header-navbar {
  flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
  margin-right: 0;
  position: static;
  top: auto;
  overflow-y: visible;
  order: 3;
  padding: 0;
  background: #5cac00;
  box-shadow: none;
  z-index: 1; }
  @media (min-width: 1200px) {
    .flix-header-navbar {
      flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
      margin-right: 15px;
      order: 2;
      background: transparent; } }

.flix-header-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  @media (min-width: 600px) {
    .flix-header-nav {
      align-items: center;
      justify-content: center; } }
  @media (min-width: 1200px) {
    .flix-header-nav {
      justify-content: flex-start; } }
  .flix-header-nav__item {
    position: relative;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 60px; }
    @media (min-width: 600px) {
      .flix-header-nav__item {
        flex-basis: auto;
        flex-wrap: nowrap;
        min-height: 0;
        padding-right: 15px; } }
    @media (min-width: 600px) {
      .flix-header-nav__item:last-child {
        padding-right: 0; } }
  .flix-header-nav__item + .flix-header-nav__item {
    border-top: 1px solid #e1e1e1; }
    @media (min-width: 600px) {
      .flix-header-nav__item + .flix-header-nav__item {
        padding-left: 15px;
        border-top: 0; } }
    @media (min-width: 600px) {
      .flix-header-nav__item + .flix-header-nav__item::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        display: inline-block;
        height: 16px;
        margin-top: -8px;
        border-left: 1px solid #fff; } }
  .flix-header-nav__link {
    position: relative;
    display: block;
    flex-basis: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 60px;
    line-height: 60px;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer; }
    @media (min-width: 600px) {
      .flix-header-nav__link {
        flex-basis: auto;
        height: 40px;
        line-height: 40px;
        color: #fff; } }
    @media (min-width: 1200px) {
      .flix-header-nav__link {
        height: 60px;
        line-height: 60px; } }
  .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
    content: '';
    position: absolute;
    left: -15px;
    height: 100%;
    border-left: 4px solid #ffad00; }
    @media (min-width: 600px) {
      .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
        left: auto;
        width: 100%;
        border-left: 0;
        border-bottom: 4px solid #fff; } }
  .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e808';
    position: absolute;
    right: 0; }
    @media (min-width: 600px) {
      .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
        position: static;
        right: auto; } }

.flix-header-subnav {
  display: none;
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (min-width: 600px) {
    .flix-header-subnav {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 250px;
      margin-top: -4px;
      margin-left: -135px;
      padding: 15px 0;
      background: #fff;
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); } }
  @media (min-width: 1200px) {
    .flix-header-subnav {
      margin-top: -10px; } }
  @media (min-width: 600px) {
    .flix-header-subnav::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -5px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff; } }
  .flix-header-nav__item:hover .flix-header-subnav {
    display: block; }
  .flix-header-subnav__item {
    margin-bottom: 15px; }
    @media (min-width: 600px) {
      .flix-header-subnav__item {
        margin-bottom: 0; } }
  .flix-header-subnav__link {
    position: relative;
    display: block;
    width: 100%;
    padding: 5px 15px;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer; }
    @media (min-width: 600px) {
      .flix-header-subnav__link {
        padding: 5px 30px; } }
  .flix-header-subnav__link:hover {
    padding-left: 11px;
    border-left: 4px solid #ffad00; }
    @media (min-width: 600px) {
      .flix-header-subnav__link:hover {
        padding-left: 26px; } }

.flix-header-nav-toggle {
  display: block; }
  @media (min-width: 600px) {
    .flix-header-nav-toggle {
      display: none; } }

.flix-box {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background: #fff; }
  @media (min-width: 600px) {
    .flix-box {
      padding: 30px; } }
  .flix-box--highlighted {
    border: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.flix-notification {
  position: relative;
  padding: 15px 60px;
  margin: 15px 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700; }
  .flix-notification::before {
    font-family: "flixicons";
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 1.2em;
    content: '\e80d';
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -0.5em; }
  .flix-notification--error::before {
    color: #ff5700; }
  .flix-notification--success::before {
    content: '\e82d';
    color: #73d700; }
  .flix-notification__close {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -0.5em;
    cursor: pointer; }

.flix-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1100; }
  .flix-popup--active {
    display: block; }
  .flix-popup__body {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 330px;
    max-width: 100%;
    max-height: 100%;
    padding: 30px;
    transform: translateY(-50%) translateX(-50%);
    background: #fff;
    border-radius: 5px;
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.375em;
    text-align: center;
    z-index: 1101;
    overflow-y: auto; }
    @media (min-width: 600px) {
      .flix-popup__body {
        width: 600px;
        padding: 60px; } }
  .flix-popup__icon {
    padding-bottom: 15px; }
  .flix-popup__title {
    margin: 0;
    padding-bottom: 15px;
    font-size: 28px;
    line-height: 34px;
    color: #444;
    font-weight: 700; }
  .flix-popup__content {
    padding-bottom: 15px; }
  .flix-popup__actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse; }
    @media (min-width: 600px) {
      .flix-popup__actions .flix-btn + .flix-btn {
        margin-left: 0;
        margin-right: 15px; } }

.flix-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1100; }

.flix-tag {
  display: inline-block;
  max-width: 100%;
  height: 30px;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 15px;
  background: #fff;
  border: 1px solid #73d700;
  border-radius: 15px;
  color: #73d700;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .flix-tag--success {
    line-height: 30px;
    background: #73d700;
    border: 0;
    color: #fff; }
  .flix-tag--warning {
    line-height: 30px;
    background: #ffad00;
    border: 0;
    color: #fff; }
  .flix-tag--danger {
    line-height: 30px;
    background: #ff5700;
    border: 0;
    color: #fff; }
  .flix-tag--disabled {
    line-height: 30px;
    background: #c8c8c8;
    border: 0;
    color: #fff; }

.flix-tag__icon:first-child::before {
  margin-left: 0; }

.flix-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: transparent;
  list-style-type: none; }
  .flix-progress__item {
    position: relative;
    min-width: 22px;
    height: 22px;
    background: transparent;
    color: #fff;
    border-radius: 22px;
    border: 1px solid #fff; }
    .flix-progress__item + .flix-progress__item {
      margin-left: 30px; }
    .flix-progress__item + .flix-progress__item::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -30px;
      display: block;
      border-top: 1px solid #fff;
      width: 30px; }
    .flix-progress__item--active {
      background: #fff;
      color: #73d700; }
  .flix-progress__number {
    position: absolute;
    top: 50%;
    left: -1px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-top: -11px;
    border-radius: 100%;
    border: 1px solid #fff;
    text-align: center;
    font-weight: 400; }
  .flix-progress__item--active .flix-progress__number {
    border-color: #73d700; }
  .flix-progress__text {
    display: none;
    padding: 0 15px 0 30px;
    line-height: 22px;
    font-weight: 400; }
    @media (min-width: 600px) {
      .flix-progress__text {
        display: block; } }

.flix-grid {
  display: flex;
  flex-wrap: wrap; }

.flix-col-1 {
  flex-basis: calc(0px + (100% - 165px) * 0.08333 * 1);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(0px + (100% - 165px) * 0.08333 * 1);
  margin-right: 15px; }

.flix-push-1 {
  margin-left: calc(15px + (100% - 165px) * 0.08333 * 1); }

.flix-col-2 {
  flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
  margin-right: 15px; }

.flix-push-2 {
  margin-left: calc(30px + (100% - 165px) * 0.08333 * 2); }

.flix-col-3 {
  flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
  margin-right: 15px; }

.flix-push-3 {
  margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); }

.flix-col-4 {
  flex-basis: calc(45px + (100% - 165px) * 0.08333 * 4);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(45px + (100% - 165px) * 0.08333 * 4);
  margin-right: 15px; }

.flix-push-4 {
  margin-left: calc(60px + (100% - 165px) * 0.08333 * 4); }

.flix-col-5 {
  flex-basis: calc(60px + (100% - 165px) * 0.08333 * 5);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(60px + (100% - 165px) * 0.08333 * 5);
  margin-right: 15px; }

.flix-push-5 {
  margin-left: calc(75px + (100% - 165px) * 0.08333 * 5); }

.flix-col-6 {
  flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
  margin-right: 15px; }

.flix-push-6 {
  margin-left: calc(90px + (100% - 165px) * 0.08333 * 6); }

.flix-col-7 {
  flex-basis: calc(90px + (100% - 165px) * 0.08333 * 7);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(90px + (100% - 165px) * 0.08333 * 7);
  margin-right: 15px; }

.flix-push-7 {
  margin-left: calc(105px + (100% - 165px) * 0.08333 * 7); }

.flix-col-8 {
  flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
  margin-right: 15px; }

.flix-push-8 {
  margin-left: calc(120px + (100% - 165px) * 0.08333 * 8); }

.flix-col-9 {
  flex-basis: calc(120px + (100% - 165px) * 0.08333 * 9);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(120px + (100% - 165px) * 0.08333 * 9);
  margin-right: 15px; }

.flix-push-9 {
  margin-left: calc(135px + (100% - 165px) * 0.08333 * 9); }

.flix-col-10 {
  flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
  margin-right: 15px; }

.flix-push-10 {
  margin-left: calc(150px + (100% - 165px) * 0.08333 * 10); }

.flix-col-11 {
  flex-basis: calc(150px + (100% - 165px) * 0.08333 * 11);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(150px + (100% - 165px) * 0.08333 * 11);
  margin-right: 15px; }

.flix-push-11 {
  margin-left: calc(165px + (100% - 165px) * 0.08333 * 11); }

.flix-col-12 {
  flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
  margin-right: 0; }

.flix-push-12 {
  margin-left: calc(180px + (100% - 165px) * 0.08333 * 12); }

@media (min-width: 600px) {
  .flix-col-1-sm {
    flex-basis: calc(0px + (100% - 165px) * 0.08333 * 1);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(0px + (100% - 165px) * 0.08333 * 1);
    margin-right: 15px; }
  .flix-push-1-sm {
    margin-left: calc(15px + (100% - 165px) * 0.08333 * 1); }
  .flix-col-2-sm {
    flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
    margin-right: 15px; }
  .flix-push-2-sm {
    margin-left: calc(30px + (100% - 165px) * 0.08333 * 2); }
  .flix-col-3-sm {
    flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
    margin-right: 15px; }
  .flix-push-3-sm {
    margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); }
  .flix-col-4-sm {
    flex-basis: calc(45px + (100% - 165px) * 0.08333 * 4);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(45px + (100% - 165px) * 0.08333 * 4);
    margin-right: 15px; }
  .flix-push-4-sm {
    margin-left: calc(60px + (100% - 165px) * 0.08333 * 4); }
  .flix-col-5-sm {
    flex-basis: calc(60px + (100% - 165px) * 0.08333 * 5);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(60px + (100% - 165px) * 0.08333 * 5);
    margin-right: 15px; }
  .flix-push-5-sm {
    margin-left: calc(75px + (100% - 165px) * 0.08333 * 5); }
  .flix-col-6-sm {
    flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
    margin-right: 15px; }
  .flix-push-6-sm {
    margin-left: calc(90px + (100% - 165px) * 0.08333 * 6); }
  .flix-col-7-sm {
    flex-basis: calc(90px + (100% - 165px) * 0.08333 * 7);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(90px + (100% - 165px) * 0.08333 * 7);
    margin-right: 15px; }
  .flix-push-7-sm {
    margin-left: calc(105px + (100% - 165px) * 0.08333 * 7); }
  .flix-col-8-sm {
    flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
    margin-right: 15px; }
  .flix-push-8-sm {
    margin-left: calc(120px + (100% - 165px) * 0.08333 * 8); }
  .flix-col-9-sm {
    flex-basis: calc(120px + (100% - 165px) * 0.08333 * 9);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(120px + (100% - 165px) * 0.08333 * 9);
    margin-right: 15px; }
  .flix-push-9-sm {
    margin-left: calc(135px + (100% - 165px) * 0.08333 * 9); }
  .flix-col-10-sm {
    flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
    margin-right: 15px; }
  .flix-push-10-sm {
    margin-left: calc(150px + (100% - 165px) * 0.08333 * 10); }
  .flix-col-11-sm {
    flex-basis: calc(150px + (100% - 165px) * 0.08333 * 11);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(150px + (100% - 165px) * 0.08333 * 11);
    margin-right: 15px; }
  .flix-push-11-sm {
    margin-left: calc(165px + (100% - 165px) * 0.08333 * 11); }
  .flix-col-12-sm {
    flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
    margin-right: 0; }
  .flix-push-12-sm {
    margin-left: calc(180px + (100% - 165px) * 0.08333 * 12); } }

@media (min-width: 768px) {
  .flix-col-1-md {
    flex-basis: calc(0px + (100% - 165px) * 0.08333 * 1);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(0px + (100% - 165px) * 0.08333 * 1);
    margin-right: 15px; }
  .flix-push-1-md {
    margin-left: calc(15px + (100% - 165px) * 0.08333 * 1); }
  .flix-col-2-md {
    flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
    margin-right: 15px; }
  .flix-push-2-md {
    margin-left: calc(30px + (100% - 165px) * 0.08333 * 2); }
  .flix-col-3-md {
    flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
    margin-right: 15px; }
  .flix-push-3-md {
    margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); }
  .flix-col-4-md {
    flex-basis: calc(45px + (100% - 165px) * 0.08333 * 4);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(45px + (100% - 165px) * 0.08333 * 4);
    margin-right: 15px; }
  .flix-push-4-md {
    margin-left: calc(60px + (100% - 165px) * 0.08333 * 4); }
  .flix-col-5-md {
    flex-basis: calc(60px + (100% - 165px) * 0.08333 * 5);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(60px + (100% - 165px) * 0.08333 * 5);
    margin-right: 15px; }
  .flix-push-5-md {
    margin-left: calc(75px + (100% - 165px) * 0.08333 * 5); }
  .flix-col-6-md {
    flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
    margin-right: 15px; }
  .flix-push-6-md {
    margin-left: calc(90px + (100% - 165px) * 0.08333 * 6); }
  .flix-col-7-md {
    flex-basis: calc(90px + (100% - 165px) * 0.08333 * 7);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(90px + (100% - 165px) * 0.08333 * 7);
    margin-right: 15px; }
  .flix-push-7-md {
    margin-left: calc(105px + (100% - 165px) * 0.08333 * 7); }
  .flix-col-8-md {
    flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
    margin-right: 15px; }
  .flix-push-8-md {
    margin-left: calc(120px + (100% - 165px) * 0.08333 * 8); }
  .flix-col-9-md {
    flex-basis: calc(120px + (100% - 165px) * 0.08333 * 9);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(120px + (100% - 165px) * 0.08333 * 9);
    margin-right: 15px; }
  .flix-push-9-md {
    margin-left: calc(135px + (100% - 165px) * 0.08333 * 9); }
  .flix-col-10-md {
    flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
    margin-right: 15px; }
  .flix-push-10-md {
    margin-left: calc(150px + (100% - 165px) * 0.08333 * 10); }
  .flix-col-11-md {
    flex-basis: calc(150px + (100% - 165px) * 0.08333 * 11);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(150px + (100% - 165px) * 0.08333 * 11);
    margin-right: 15px; }
  .flix-push-11-md {
    margin-left: calc(165px + (100% - 165px) * 0.08333 * 11); }
  .flix-col-12-md {
    flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
    margin-right: 0; }
  .flix-push-12-md {
    margin-left: calc(180px + (100% - 165px) * 0.08333 * 12); } }

@media (min-width: 1024px) {
  .flix-col-1-lg {
    flex-basis: calc(0px + (100% - 165px) * 0.08333 * 1);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(0px + (100% - 165px) * 0.08333 * 1);
    margin-right: 15px; }
  .flix-push-1-lg {
    margin-left: calc(15px + (100% - 165px) * 0.08333 * 1); }
  .flix-col-2-lg {
    flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
    margin-right: 15px; }
  .flix-push-2-lg {
    margin-left: calc(30px + (100% - 165px) * 0.08333 * 2); }
  .flix-col-3-lg {
    flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
    margin-right: 15px; }
  .flix-push-3-lg {
    margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); }
  .flix-col-4-lg {
    flex-basis: calc(45px + (100% - 165px) * 0.08333 * 4);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(45px + (100% - 165px) * 0.08333 * 4);
    margin-right: 15px; }
  .flix-push-4-lg {
    margin-left: calc(60px + (100% - 165px) * 0.08333 * 4); }
  .flix-col-5-lg {
    flex-basis: calc(60px + (100% - 165px) * 0.08333 * 5);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(60px + (100% - 165px) * 0.08333 * 5);
    margin-right: 15px; }
  .flix-push-5-lg {
    margin-left: calc(75px + (100% - 165px) * 0.08333 * 5); }
  .flix-col-6-lg {
    flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
    margin-right: 15px; }
  .flix-push-6-lg {
    margin-left: calc(90px + (100% - 165px) * 0.08333 * 6); }
  .flix-col-7-lg {
    flex-basis: calc(90px + (100% - 165px) * 0.08333 * 7);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(90px + (100% - 165px) * 0.08333 * 7);
    margin-right: 15px; }
  .flix-push-7-lg {
    margin-left: calc(105px + (100% - 165px) * 0.08333 * 7); }
  .flix-col-8-lg {
    flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
    margin-right: 15px; }
  .flix-push-8-lg {
    margin-left: calc(120px + (100% - 165px) * 0.08333 * 8); }
  .flix-col-9-lg {
    flex-basis: calc(120px + (100% - 165px) * 0.08333 * 9);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(120px + (100% - 165px) * 0.08333 * 9);
    margin-right: 15px; }
  .flix-push-9-lg {
    margin-left: calc(135px + (100% - 165px) * 0.08333 * 9); }
  .flix-col-10-lg {
    flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
    margin-right: 15px; }
  .flix-push-10-lg {
    margin-left: calc(150px + (100% - 165px) * 0.08333 * 10); }
  .flix-col-11-lg {
    flex-basis: calc(150px + (100% - 165px) * 0.08333 * 11);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(150px + (100% - 165px) * 0.08333 * 11);
    margin-right: 15px; }
  .flix-push-11-lg {
    margin-left: calc(165px + (100% - 165px) * 0.08333 * 11); }
  .flix-col-12-lg {
    flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
    margin-right: 0; }
  .flix-push-12-lg {
    margin-left: calc(180px + (100% - 165px) * 0.08333 * 12); } }

@media (min-width: 1200px) {
  .flix-col-1-xl {
    flex-basis: calc(0px + (100% - 165px) * 0.08333 * 1);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(0px + (100% - 165px) * 0.08333 * 1);
    margin-right: 15px; }
  .flix-push-1-xl {
    margin-left: calc(15px + (100% - 165px) * 0.08333 * 1); }
  .flix-col-2-xl {
    flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
    margin-right: 15px; }
  .flix-push-2-xl {
    margin-left: calc(30px + (100% - 165px) * 0.08333 * 2); }
  .flix-col-3-xl {
    flex-basis: calc(30px + (100% - 165px) * 0.08333 * 3);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(30px + (100% - 165px) * 0.08333 * 3);
    margin-right: 15px; }
  .flix-push-3-xl {
    margin-left: calc(45px + (100% - 165px) * 0.08333 * 3); }
  .flix-col-4-xl {
    flex-basis: calc(45px + (100% - 165px) * 0.08333 * 4);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(45px + (100% - 165px) * 0.08333 * 4);
    margin-right: 15px; }
  .flix-push-4-xl {
    margin-left: calc(60px + (100% - 165px) * 0.08333 * 4); }
  .flix-col-5-xl {
    flex-basis: calc(60px + (100% - 165px) * 0.08333 * 5);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(60px + (100% - 165px) * 0.08333 * 5);
    margin-right: 15px; }
  .flix-push-5-xl {
    margin-left: calc(75px + (100% - 165px) * 0.08333 * 5); }
  .flix-col-6-xl {
    flex-basis: calc(75px + (100% - 165px) * 0.08333 * 6);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(75px + (100% - 165px) * 0.08333 * 6);
    margin-right: 15px; }
  .flix-push-6-xl {
    margin-left: calc(90px + (100% - 165px) * 0.08333 * 6); }
  .flix-col-7-xl {
    flex-basis: calc(90px + (100% - 165px) * 0.08333 * 7);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(90px + (100% - 165px) * 0.08333 * 7);
    margin-right: 15px; }
  .flix-push-7-xl {
    margin-left: calc(105px + (100% - 165px) * 0.08333 * 7); }
  .flix-col-8-xl {
    flex-basis: calc(105px + (100% - 165px) * 0.08333 * 8);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(105px + (100% - 165px) * 0.08333 * 8);
    margin-right: 15px; }
  .flix-push-8-xl {
    margin-left: calc(120px + (100% - 165px) * 0.08333 * 8); }
  .flix-col-9-xl {
    flex-basis: calc(120px + (100% - 165px) * 0.08333 * 9);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(120px + (100% - 165px) * 0.08333 * 9);
    margin-right: 15px; }
  .flix-push-9-xl {
    margin-left: calc(135px + (100% - 165px) * 0.08333 * 9); }
  .flix-col-10-xl {
    flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
    margin-right: 15px; }
  .flix-push-10-xl {
    margin-left: calc(150px + (100% - 165px) * 0.08333 * 10); }
  .flix-col-11-xl {
    flex-basis: calc(150px + (100% - 165px) * 0.08333 * 11);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(150px + (100% - 165px) * 0.08333 * 11);
    margin-right: 15px; }
  .flix-push-11-xl {
    margin-left: calc(165px + (100% - 165px) * 0.08333 * 11); }
  .flix-col-12-xl {
    flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
    flex-grow: 0;
    flex-shrink: 0;
    max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
    margin-right: 0; }
  .flix-push-12-xl {
    margin-left: calc(180px + (100% - 165px) * 0.08333 * 12); } }

.flix-col {
  flex: 1;
  margin-right: 15px; }

[class*=flix-col]:last-child,
.flix-col--last {
  margin-right: 0; }

.main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 0;
  background: #fff; }
  @media (min-width: 1024px) {
    .main-wrapper {
      padding-top: 60px; } }

.page {
  min-width: 320px;
  max-width: 1200px;
  margin: 0 auto 90px;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1024px) {
    .page {
      padding-bottom: 90px; } }

.page__content {
  flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
  margin-right: 0; }
  @media (min-width: 768px) {
    .page__content {
      flex-basis: calc(135px + (100% - 165px) * 0.08333 * 10);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(135px + (100% - 165px) * 0.08333 * 10);
      margin-right: 0; } }

.page__sidebar {
  flex-basis: calc(165px + (100% - 165px) * 0.08333 * 12);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(165px + (100% - 165px) * 0.08333 * 12);
  margin-right: 0; }
  @media (min-width: 768px) {
    .page__sidebar {
      flex-basis: calc(15px + (100% - 165px) * 0.08333 * 2);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: calc(15px + (100% - 165px) * 0.08333 * 2);
      margin-right: 15px; } }

.left {
  float: left; }

.right {
  float: right; }
